import React from "react";
import { Link } from "react-router-dom";

const Footer = () => {
	return (
		<>
			<div className="footer-container bg-primaryBgColor pt-1 pb-3 mt-8">
				<ul className="flex mt-4 pt-2 pb-4 justify-between">
					<li className="w-2/5 footer-section">
						<p className="font-semibold text-2xl text-gray-50 mb-2">
							KONNECTORX
						</p>
						<span className="text-gray-50 text-sm">
							 
							{/* <p>
							KonnectorX is a business networking platform that aims to help connect 
							startups, founders, investors, and job seekers.
							Platform also provides private and public company business information. 
							</p> */}
							konnectorx platform aim to simplify the process of connecting 
							startups, founders, investors, and job seekers, and provide 
							a valuable resource for anyone looking to find job opportunities, 
							raise funds, or connect with the startup community.
							
		
						</span>
                      
						<span className="text-gray-50 text-sm">
							<p>
								{/* konnectorx platform aim to simplify the process of connecting 
								startups, founders, investors, and job seekers, and provide 
								a valuable resource for anyone looking to find job opportunities, 
								raise funds, or connect with the startup community. */}

								Additionally, at KonnectorX, we understand the importance of staying informed and 
								inspired. That's why we bring you aggregated news on startup businesses, industry trends, 
								and motivational stories. Stay up-to-date with the latest happenings in the startup world, 
								gain insights from successful entrepreneurs, and find the motivation you need to fuel your 
								own journey.
							</p>
						</span>
					</li>
					<span className="w-3/6 flex ml-12 footer-section-container">
						<li className="w-1/4  footer-section-link text-white">
							<p className="mb-2">USEFUL LINKS</p>
							<li className="mb-2">
								{/* <Link to={"/aboutus"} target="_blank"> */}
								<a href="/aboutus" target={"_blank"}>
									<p className="text-xs font-semibold !text-left">About</p>
								</a>
								{/* </Link> */}
							</li>
							<li className="mb-2">
								{/* <Link to={"/aboutus"} target="_blank"> */}
								<a href="/blogs" target={"_blank"}>
									<p className="text-xs font-semibold !text-left">Blogs</p>
								</a>
								{/* </Link> */}
							</li>

							<li className="mb-2">
								<p className="text-xs font-semibold">
									<Link to="/publicstartup">Startup</Link>
								</p>
							</li>
							<li className="mb-2">
								<p className="text-xs font-semibold">
									<Link to="/publicinvestor">Investor</Link>
								</p>
							</li>
							<li className="mb-2">
								<p className="text-xs font-semibold">
									<Link to="/publicjobs">Jobs</Link>
								</p>
							</li>
						</li>
						<li className="w-1/3  footer-section-link text-white">
							<p className="mb-2">IMPORTANT LINKS</p>
							<li className="mb-2">
								<p className="text-xs font-semibold">
									<a href="/contactus" target={"_blank"}>
										Contact Us
									</a>
								</p>
							</li>
							<li className="mb-2">
								{/* <Link to={"/privacy"} target="_blank"> */}
								<a href="/privacy" target={"_blank"}>
									<p className="text-xs font-semibold">Privacy</p>
								</a>
								{/* </Link> */}
							</li>
							<li className="mb-2">
								{/* <Link to={"/privacy"} target="_blank"> */}
								<a href="https://merchant.razorpay.com/policy/LzqPQ8TIxqq9ZV/terms" target={"_blank"}>
									<p className="text-xs font-semibold">Terms and Conditions</p>
								</a>
								{/* </Link> */}
							</li>
							<li className="mb-2">
								{/* <Link to={"/privacy"} target="_blank"> */}
								<a href="https://merchant.razorpay.com/policy/LzqPQ8TIxqq9ZV/refund" target={"_blank"}>
									<p className="text-xs font-semibold">Cancellation and Refund Policy</p>
								</a>
								{/* </Link> */}
							</li>
							<li className="mb-2">
								{/* <Link to={"/privacy"} target="_blank"> */}
								<a href="https://merchant.razorpay.com/policy/LzqPQ8TIxqq9ZV/shipping" target={"_blank"}>
									<p className="text-xs font-semibold">Shipping and Delivery Policy</p>
								</a>
								{/* </Link> */}
							</li>
						</li>
						<li className="footer-section-contact w-2/4 text-white">
							<p className="mb-2">CONTACT US</p>
							<span className="text-sm">
								5th Floor, Unit No.-534-535 Spaze ITech Park, Sohna Road Sector
								49, Gurgaon 122018 Haryana <br />
								+91-9718214022 <br />
								admin@koderbox.com
							</span>
							<ul className="!flex mt-4" id="footer-social">
								<li className="mr-3">
									<i title="Linkedin">
										<a
											href="https://www.linkedin.com/company/konnectorx/"
											target="_blank"
										>
											<img
												src="../img/icons/linkedinwhite.png"
												// alt="linkedin"
												className="user-social linkedin h-6"
											/>
										</a>
									</i>
								</li>
								<li className="mr-3">
									<i title="twitter">
										<a href="https://twitter.com/konnectorx" target="_blank">
											<img
												src="../img/icons/twitterwhite.png"
												// alt="linkedin"
												className="user-social linkedin h-6"
											/>
										</a>
									</i>
								</li>
								<li className="mr-3">
									<i title="facebook">
										<a
											href="https://www.facebook.com/konnectorx"
											target="_blank"
										>
											<img
												src="../img/icons/facebookwhite.png"
												// alt="linkedin"
												className="user-social linkedin h-6"
											/>
										</a>
									</i>
								</li>
								<li className="mr-3">
									<i title="instagram">
										<a
											href="https://www.instagram.com/konnectorx/"
											target="_blank"
										>
											<img
												src="../img/icons/instagramwhite.png"
												// alt="linkedin"
												className="user-social linkedin h-6"
											/>
										</a>
									</i>
								</li>
							</ul>
						</li>
					</span>

					{/* <div className=" top-2/3 border-b-2 border-black w-2/3 flex justify-center"></div> */}
				</ul>

				<p className="text-center text-white text-sm mt-1 footer-copyright">
					© Copyright <span className="font-semibold">Konnectorx.</span>
					All Rights Reserved
				</p>
			</div>
		</>
	);
};

export default Footer;
